<template>
    <div>
        <div class="mybet" v-for="(betinfo,index) in betList" :key="index">
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_NOT">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList" :key="index2">

                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">
                                <span class="" v-if="$store.state.country === i18nConst.KR">{{betInfoGame.game.league.leagueName}}</span>
                                <span class="" v-if="$store.state.country === i18nConst.EN">{{betInfoGame.game.league.enname}}</span>
                                <span class="" v-if="$store.state.country === i18nConst.JP">{{betInfoGame.game.league.jpname}}</span>
                                <span class="" v-if="$store.state.country === i18nConst.FRA">{{betInfoGame.game.league.franame}}</span>
                                <span class="" v-if="$store.state.country === i18nConst.SPA">{{betInfoGame.game.league.spaname}}</span>

                            </span>

                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}

                            </span>
                            <span class="score text-red"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                      {{i18nConst.COMMON['CANCELL'][$store.state.country]}}
                                    </span>
                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">
                                      {{i18nConst.BET['HIT_SPECIAL'][$store.state.country]}}
                                    </span>
                            <span class="score text-white"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_WATING">
                                     {{i18nConst.BET['WAITING'][$store.state.country]}}
                                    </span>
                            <span class="st"
                                  style="float: right">{{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}</span>
                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_HOME}">
                                        <span class="home_name">
                                            <span class="" v-if="$store.state.country === i18nConst.KR">{{betInfoGame.game.homeTeamName}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.EN">{{betInfoGame.game.ennamehome}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.JP">{{betInfoGame.game.jpnamehome}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.FRA">{{betInfoGame.game.franamehome}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.SPA">{{betInfoGame.game.spanamehome}}</span>

                                        </span>
                                        <span class="home_odds">
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-green"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-up text-red"></i>
                                            {{betInfoGame.homeTeamOdds}}
                                        </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds > 0"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_DRAW}">
                                        <span class="draw_bedang">{{betInfoGame.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                         class="draw ho">
                                        {{betInfoGame.handicapVal}}
                                    </div>
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                         class="draw ho">
                                        {{betInfoGame.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_AWAY}">
                                        <span class="away_name">
                                            <span class="" v-if="$store.state.country === i18nConst.KR">{{betInfoGame.game.awayTeamName}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.EN">{{betInfoGame.game.ennameaway}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.JP">{{betInfoGame.game.jpnameaway}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.FRA">{{betInfoGame.game.franameaway}}</span>
                                            <span class="" v-if="$store.state.country === i18nConst.SPA">{{betInfoGame.game.spanameaway}}</span>

                                        </span>
                                        <span class="away_odds">
                                            {{betInfoGame.awayTeamOdds}}
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-green"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-down text-blue"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                 <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                       {{i18nConst.BET['WINNING'][$store.state.country]}}
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            {{i18nConst.BET['LOSE'][$store.state.country]}}
                                </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                     {{i18nConst.BET['HIT_SPECIAL'][$store.state.country]}}
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 4">
                                     {{i18nConst.COMMON['CANCELL'][$store.state.country]}}
                                  </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table">
                                    <tr>
                                        <th>{{i18nConst.BET['BET_TYPE'][$store.state.country]}}</th>
                                        <th>{{i18nConst.BET['DIVISION'][$store.state.country]}}</th>
                                        <th>{{i18nConst.BET['OPTIONAL'][$store.state.country]}}</th>
                                        <th>{{i18nConst.BET['RATE'][$store.state.country]}}</th>
                                        <th>{{i18nConst.BET['BETING_RESULT'][$store.state.country]}}</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td class=" text-dark bg-color-blue" style="font-weight: 600">{{betInfoGame.ito3}}</td>
                                        <td>{{betInfoGame.selectedOdds}} <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text-white" v-if="betInfoGame.betGameResult === 0">
                                                {{i18nConst.BET['WAITING'][$store.state.country]}}
                                              </span>
                                            <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                                {{i18nConst.BET['WINNING'][$store.state.country]}}
                                         </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            {{i18nConst.BET['LOSE'][$store.state.country]}}
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                             {{i18nConst.BET['HIT_SPECIAL'][$store.state.country]}}
                                          </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 4">
                                             {{i18nConst.COMMON['CANCELL'][$store.state.country]}}
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="betInfo">
                <span class="chk">

<!--                  <input v-if="position" type="checkbox"-->
<!--                         @click="checkBetId(betinfo.id)">-->
                     <a href="javascript:void(0)" class="badge badge-success"
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING
                       && betinfo.isinplay === sportsConst.INPLAY_NOT"
                        @click="cancelBet(betinfo.id)">{{i18nConst.COMMON['CANCELL'][$store.state.country]}}</a>
                    <a href="javascript:void(0)" class="badge badge-success"
                       v-if="betinfo.betResult !== sportsConst.BET_RESULT_WAITING"
                       @click="deleteBet(betinfo.id)">{{i18nConst.COMMON['DELETE'][$store.state.country]}}</a>
                </span>
                <span class="time">
                  <i class="fa fa-clock-o"></i>&nbsp;
                  <i>{{betinfo.createTime|datef('MM/DD HH:mm')}}</i>
                </span>
                <span
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">{{i18nConst.BET['WAITING'][$store.state.country]}}</span>
                <span class="text-skyblue"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_WIN"><i class="fa fa-trophy"></i> {{i18nConst.BET['WINNING'][$store.state.country]}}</span>
                <span class="text-red"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_LOSE">{{i18nConst.BET['LOSE'][$store.state.country]}}</span>
                <span class="text-orange"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_SPECIALCASE">[{{i18nConst.BET['HIT_SPECIAL'][$store.state.country]}}]</span>
                <span class="text-red"
                      v-if="betinfo.betResult === sportsConst.BET_RESULT_CANCEL">[{{i18nConst.COMMON['CANCELL'][$store.state.country]}}]
                            </span>


                <span class="odds text-orange">{{i18nConst.BET['RATE'][$store.state.country]}}: {{betinfo.betOddsTotal}}</span>
                <span class="betCash text-skyblue">{{i18nConst.BET['BET_CASH'][$store.state.country]}}: $ {{betinfo.betCash|comma}}</span>
                <span class="wincash text-skyblue">
                {{i18nConst.BET['EXPECTED_WIN_CASH'][$store.state.country]}}: $
                {{(betinfo.totalCash)|comma}}&nbsp;<i v-if="betinfo.bonusCash > 0"
                                                                        style="font-style: normal">(+{{betinfo.bonusCash|comma}})</i>
                </span>



<!--                <span v-if="position">-->
<!--                    <a href="javascript:void(0)" class="badge badge-success"-->
<!--                       v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING-->
<!--                       && betinfo.isinplay === sportsConst.INPLAY_NOT"-->
<!--                       @click="cancelBet(betinfo.id)">취소</a>-->
<!--                    <a href="javascript:void(0)" class="badge badge-success"-->
<!--                       v-if="betinfo.betResult !== sportsConst.BET_RESULT_WAITING"-->
<!--                       @click="deleteBet(betinfo.id)">삭제</a>-->
<!--                </span>-->

            </div>
        </div>

    </div>

</template>

<script>
    import {cancelBet, deleteBet} from "../../network/sportsBetRequest";
    import sportsConst from "../../common/sportsConst";
    import BoardWriter from "../BoardWriter";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "SportsBetListComp",
        components: {BoardWriter},
        data() {
            return {
                i18nConst:i18nConst,
                sportsConst,
                betIds: [],
            }
        },
        props: {
            betList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
            }
        },
        methods: {
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            cancelBet(id) {
                this.$swal({
                    title: i18nConst.BET['CANCELL_BET_MSG_1'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' '+i18nConst.COMMON['YES'][this.$store.state.country]+' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    if (res.value) {
                        cancelBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            deleteBet(id) {

                this.$swal({
                    title: i18nConst.BET['DELETE_BET_MSG_1'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' '+i18nConst.COMMON['YES'][this.$store.state.country]+' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    if (res.value) {
                        deleteBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .league .score {
        float: right;
        margin: 0 3px;
    }

    .league .st {
        display: none;
    }

    .betInfo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #0e3b88;
        line-height: 36px;
        margin-bottom: 20px;
        border-bottom: 4px solid #092a4c;
        border-radius: 3px;
    }

    .betInfo span {
        display: block;
        width: 33.3%;
        color: #c4c4c3;
        box-sizing: border-box;
        padding-left: 3px;
    }

    .match_area table {
        font-size: 12px;
    }

    .match_area table th {

    }

    .match_area table th, td {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #1a4050;
    }

    .match_area .inplay_team_name {
        margin-left: 20px;
        color: #f4c600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .league .st {
            display: inline-block;
            color: #c4c4c3;
            margin-right: 20px;
        }

        .match_area .inplay_team_name {
            margin-left: 5px;
        }
    }


</style>